import './NewPin.css';
import { Marker, MarkerDragEvent, Popup as PopusDefault } from 'react-map-gl';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { MdLocationPin } from 'react-icons/md';
import { NewPinInfo } from './NewPinInfo/NewPinInfo';

interface Props {
  setShowCreatorPin: Dispatch<SetStateAction<boolean>>;
  lat: number;
  lng: number;
  zoom: number;
}

export const NewPin = (props: Props) => {

  const [stateBeforeDragStart, setStateBeforeDragStart] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [marker, setMarker] = useState({
    latitude: props.lat,
    longitude: props.lng,
  });

  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  const onMarkerStartDrag = useCallback((event: MarkerDragEvent) => {
    setStateBeforeDragStart(showPopup);
    setShowPopup(false);
  }, [showPopup]);

  const onMarkerEndDrag = useCallback((event: MarkerDragEvent) => {
    if (stateBeforeDragStart) {
      setShowPopup(true);
    }
  }, [stateBeforeDragStart]);

  return (
    <div className="NewPin">
      <Marker longitude={ marker.longitude }
              latitude={ marker.latitude }
              draggable
              anchor="bottom"
              offset={[0 , 10]}
              onDragStart={ onMarkerStartDrag }
              onDragEnd={ onMarkerEndDrag }
              onDrag={ onMarkerDrag }
              onClick={ () => setShowPopup(true) }>
        <MdLocationPin className="Marker"
                       style={ { color: '#3e0b94', fontSize: '3rem' } } />
      </Marker>
      { showPopup && (
        <PopusDefault longitude={ marker.longitude }
                      latitude={ marker.latitude }
                      closeButton
                      offset={ 3 }
                      closeOnClick={ false }
                      onClose={ () => setShowPopup(false) }
                      focusAfterOpen
                      anchor="top">
          <NewPinInfo setShow={ props.setShowCreatorPin }
                      lat={ marker.latitude }
                      lng={ marker.longitude } />
        </PopusDefault>
      ) }
    </div>
  );
};