import { Register } from './Register/Register';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';

import './AuthBox.css';
import { Login } from './Login/Login';
import { useHandleBlur } from '../../utils/handleBlur';


export const AuthBox = () => {

  const path = useLocation().pathname;
  const handleBlur = useHandleBlur();


  return (
    <div className="AuthBox">
      <div className="Auth" onBlur={ handleBlur } tabIndex={ 1 }>
        <Link to="/"><IoClose  className="Auth__close-icon"/></Link>
      { path === '/login' ? (
        <Login />
      ) : (
        <Register />
      ) }
      </div>
    </div>
  );
};
