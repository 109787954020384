import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import './Loading.css';

interface Props {
  className?: string;
}

export const Loading = ({ className }: Props) => {

  return (
    <AiOutlineLoading3Quarters className={`Loading ${ className }`}/>
  );
};