import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';
import { axios } from '../api/axios';

export const useLogout = () => {
  const { auth, setAuth } = useAuth();

  const navigate = useNavigate();

  return async function() {
    localStorage.removeItem('user');
    await axios.patch('users/logout', { id: auth?.id }, { withCredentials: true });
    setAuth(null);
    navigate('/');
  }
}