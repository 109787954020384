import { InputField } from '../../common/InputField/InputField';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { axiosPrivate } from '../../../api/axios';
import { useAuth } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export const Login = () => {

  const [form, setForm] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const emailRef = useRef<HTMLInputElement>(null!)
  const pwdRef = useRef<HTMLInputElement>(null!);

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setError('');
  }, [form])

  useEffect(() => {
    emailRef.current.focus();
  }, [])

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      const { data } = await axiosPrivate.post('users/login', form);
      setAuth(data);
      localStorage.setItem('user', JSON.stringify({ username: data.username, id: data.id }));
      navigate('/');
    } catch (e: any) {
      const message = e.response.data.message ?? 'Something went wrong. Try again later.'
      setError(message);
    }
  }

  return (
    <div className="Login">
      <form onSubmit={ handleSubmit }
            className="Login__form">
        <h2 className="noselect">Login to your account</h2>

        <InputField label="email">
          <input type="email" ref={ emailRef } required value={ form.email } onChange={ e => setForm(prev => ({...prev, email: e.target.value })) } />
        </InputField>

        <InputField label="password" pwdRef={ pwdRef }>
          <input type="password" ref={ pwdRef } required value={ form.password } onChange={ e => setForm(prev => ({...prev, password: e.target.value })) } />
        </InputField>

        { !!error && <p className="error">{ error }</p> }

        <button className="noselect">login</button>
      </form>
    </div>
  );
};