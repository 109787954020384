import { Link, useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { InputField } from '../InputField/InputField';
import { useHandleBlur } from '../../../utils/handleBlur';
import { Dispatch, FormEvent, SetStateAction, useEffect, useRef } from 'react';
import './AdForm.css';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  handleSubmit: (e: FormEvent) => Promise<void>;
  form: { title: string, description: string, price: number, url: string };
  setForm: Dispatch<SetStateAction<{ title: string, description: string, price: number, url: string }>>
  error: string,
  success?: string;
  type: 'edit' | 'add';
}

export const AdForm = ({ handleSubmit, form, setForm, error, success, type }: Props) => {

  const handleBlur = useHandleBlur();
  const titleRef = useRef<HTMLInputElement>(null!);
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate('/');
    }
  }, [auth, navigate]);


  useEffect(() => {
    titleRef.current.focus();
  }, [titleRef]);

  const handleChange = (key: string, value: string | number) => {
    setForm(prev => ({ ...prev, [key]: value }));
  };

  return (
    <div className="AdForm">
      <div className="AdForm__body"
           onBlur={ handleBlur }
           tabIndex={ 1 }>
        <Link to="/"><IoClose className="Auth__close-icon" /></Link>
        <form onSubmit={ handleSubmit }>
          <h2>{ type === 'edit' ? 'Edit' : 'Add new' }</h2>
          <InputField label="Title">
            <input ref={ titleRef }
                   type="text"
                   required
                   onChange={ e => handleChange('title', e.target.value) }
                   value={ form.title } />
          </InputField>

          <InputField label="Desrciption">
            <textarea placeholder="you can leave it empty"
                      onChange={ e => handleChange('description', e.target.value) }
                      value={ form.description } />
          </InputField>

          <InputField label="Price">
            <input type="number"
                   max={ 99999999 }
                   min={ 0 }
                   onChange={ e => handleChange('price', Number(e.target.value)) }
                   value={ form.price.toString() } />
          </InputField>

          <InputField label="Link url">
            <input type="url"
                   required
                   onChange={ e => handleChange('url', e.target.value) }
                   value={ form.url } />
          </InputField>

          { !!error && <p className="error">{ error }</p> }
          { success && (
            <div>
              <p className="success">{ success }</p>
              { type === 'add' && <p className="success">Click <Link to="/login">here</Link> to login.</p> }
            </div>
          ) }

          <button type="submit">
            { type === 'edit' ? 'Edit' : 'Add' }
          </button>
        </form>
      </div>
    </div>
  );
};