import { AdEntityWithUsername } from 'types';
import { formatDistance } from 'date-fns';
import './Card.css';
import { AuthorOptions } from './AuthorOptions/AuthorOptions';

interface Props {
  ad: Required<AdEntityWithUsername>;
}

export const Card = ({ ad }: Props) => {

  return (
    <div className="Card">
      <a className="Card__link"
         href={ ad.url }
         rel="noreferrer"
         target="_blank"
         aria-label="original advert"
      >
        <h2 className="Card__title">
          { ad.title }
        </h2>
      </a>
      <p className="Card__description">{ ad.description }</p>
      { !!ad.price && <p className="Card__price">Price: { ad.price }$</p> }

      <p className="Card__info">
        <span>{ formatDistance(new Date(ad.createdAt), new Date(), { addSuffix: true }) }</span> - <span>{ ad.username }</span>
      </p>
      <AuthorOptions adId={ ad.id } authorId={ ad.userId } />
    </div>
  );
};