import { Marker as MarkerDef, PointLike } from 'react-map-gl';
import { MdLocationPin } from 'react-icons/md';
import './Marker.css';
import React from 'react';
import { SmallAdEntity } from 'types';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  pin: SmallAdEntity;
  zoom: number;
  setCurrentPinId: React.Dispatch<React.SetStateAction<string | null>>;
  flyTo: (lat: number, lng: number) => void;
}

export const Marker = ({ pin, zoom, setCurrentPinId, flyTo  }: Props) => {

  const { auth } = useAuth();

  const handleClick = () => {
    setCurrentPinId(pin.id);
    flyTo(pin.lat, pin.lng);
  };

  return (
    <MarkerDef longitude={ pin.lng }
               latitude={ pin.lat }
               anchor="bottom"
               offset={[0 , 10]}
    >
      <MdLocationPin className="Marker" style={{ fontSize: `${ zoom / 3 }rem`, color: pin.userId === auth?.id ? 'green' : '#0294ef' }}  onClick={ handleClick }/>
      <span className="Marker__shadow"/>
    </MarkerDef>
  );
};


// style={{ fontSize: `${ zoom / 3 }rem`, color: pin.userId === auth?.id ? 'green' : '#0294ef' }}style={{ fontSize: `${ zoom / 3 }rem`, color: pin.userId === auth?.id ? 'green' : '#0294ef' }}