import { FormEvent, useEffect, useRef, useState } from 'react';
import { InputField } from '../../common/InputField/InputField';
import './Register.css';
import { validateForm } from '../../../utils/validateForm';
import { Link } from 'react-router-dom';
import { axios } from '../../../api/axios';

export const Register = () => {

  const [form, setForm] = useState({ username: '', email: '', password: '', passwordRepetition: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const usernameRef = useRef<HTMLInputElement>(null!);
  const pwdRef = useRef<HTMLInputElement>(null!);
  const pwdRepRef = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  useEffect(() => {
    setError('');
  }, [form]);

  const handleChange = (key: string, value: string) => {
    setForm(prev => ({ ...prev, [key]: value }));
  }

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      validateForm(form);
      const { data } = await axios.post('users/register', form);
      setSuccess(true);
      setForm({ username: '', email: '', password: '', passwordRepetition: '' });
    } catch (e: any) {
      const message = e.response?.data?.message ?? e.message;
      setError(message);
    }
  };

  return (
    <div className="Register">
      <form onSubmit={ handleSubmit }
            className="Register__form">
        <h2 className="noselect">Create new account</h2>


        <InputField label="username">
          <input type="text"
                 ref={ usernameRef }
                 value={ form.username }
                 onChange={ e => handleChange('username', e.target.value) }
                 required
                 min={ 4 }
                 max={ 25 } />
        </InputField>

        <InputField label="email">
          <input type="email"
                 value={ form.email }
                 onChange={ e => handleChange('email', e.target.value) }
                 required
                 max={ 254 } />
        </InputField>

        <InputField label="password" pwdRef={ pwdRef }>
          <input type="password"
                 ref={ pwdRef }
                 value={ form.password }
                 onChange={ e => handleChange('password', e.target.value) }
                 required
                 min={ 6 }
                 max={ 100 } />
        </InputField>

        <InputField label="repeat password" pwdRef={ pwdRepRef }>
          <input type="password"
                 ref={ pwdRepRef }
                 value={ form.passwordRepetition }
                 onChange={ e => handleChange('passwordRepetition', e.target.value) }
                 required
                 min={ 6 }
                 max={ 100 } />
        </InputField>

        { !!error && <p className="error">{ error }</p> }
        { success && <div><p className="success">Success! You can now login by your email and password.</p><p className="success">Click <Link to="/login">here</Link> to login.</p></div>}

        <button className="noselect">create account</button>
      </form>
    </div>
  );
};