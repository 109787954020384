import React from 'react';
import { Route, Routes, useRoutes } from 'react-router-dom';
import './App.css';
import { AuthBox } from './components/AuthBox/AuthBox';
import { MainLayout } from './Layouts/MainLayout';
import { AdCreator } from './components/AdCreator/AdCreator';
import { AdEditor } from './components/AdEditor/AdEditor';



export const App = () => {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <MainLayout /> }>
          <Route path="/login" element={ <AuthBox /> } />
          <Route path="/register" element={ <AuthBox /> } />
          <Route path="/create-ad/:lat/:lng" element={ <AdCreator /> } />
          <Route path="/edit-ad/:adId" element={ <AdEditor /> } />
        </Route>
      </Routes>
    </div>
  );
};