import { Popup as PopusDefault, PointLike } from 'react-map-gl';
import React, { useEffect, useState } from 'react';
import { AdEntityWithUsername, SmallAdEntity } from 'types';
import { axios } from '../../../api/axios';
import { Card } from './Card/Card';
import { Loading } from '../../common/Loading/Loading';
import './Popup.css';
import { useSmallAds } from '../../../hooks/useSmallAds';

interface Props {
  pin: SmallAdEntity;
  closePopup: () => void;
  zoom: number;
}

export const Popup = ({ pin, closePopup, zoom }: Props) => {

  const { smallAds } = useSmallAds();
  const [ad, setAd] = useState<Required<AdEntityWithUsername> | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`annoucements/${ pin.id }`);
        setAd(data.ad);
      } finally {
        setLoading(false);
      }
    })();
  }, [smallAds]);


  return (
    <PopusDefault longitude={ pin.lng }
                  latitude={ pin.lat }
                  closeButton
                  offset={ 3 }
                  closeOnClick={ false }
                  onClose={ closePopup }
                  maxWidth={ '260px' }
                  anchor="top">
      { loading && <Loading className="Popup__loding-icon"/> }
      { !!ad && <Card ad={ ad } /> }
    </PopusDefault>
  );
};