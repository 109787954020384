import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import './AuthBtns.css';
import { useLogout } from '../../../../hooks/useLogout';

export const AuthBtns = () => {

  const { auth } = useAuth();
  const logout = useLogout();


  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await logout();
  }

  return (
    <div className="AuthBtns">
      { !auth ? (
        <>
          <button className="AuthBtns__btn"><Link className="AuthBtns__link" to="/login">Login</Link></button>
          <button className="AuthBtns__btn"><Link className="AuthBtns__link" to="/register">Register</Link></button>
        </>
      ) : (
        <button className="AuthBtns__btn"><Link className="AuthBtns__link" onClick={ handleLogout } to="logout">Logout</Link></button>
      ) }
    </div>
  );
};