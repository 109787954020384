import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';

export const AuthContext = createContext<{ auth: State | null, setAuth: Dispatch<SetStateAction<State | null>> }>({
  auth: null, setAuth: () => {
  },
});

interface State {
  id: string;
  accessToken: string;
  username: string;
}

interface Props {
  children?: React.ReactNode;
}

export const AuthProvider = ({ children }: Props) => {

  const [auth, setAuth] = useState<State | null>(null);

  useEffect(() => {
    const userJSON = localStorage.getItem('user');
    if (userJSON) {
      const user = JSON.parse(userJSON);
      setAuth(user);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      { children }
    </AuthContext.Provider>
  );
};
