import Map, { MapRef, NavigationControl } from 'react-map-gl';
import { useEffect, useRef, useState } from 'react';
import { useSearch } from '../../hooks/useSearch';
import { axios } from '../../api/axios';
import { Pin } from '../Pin/Pin';
import './Map.css';
import { NewPin } from '../NewPin/NewPin';
import { CreateNewAdBtn } from '../CreateNewAdBtn/CreateNewAdBtn';
import { useSmallAds } from '../../hooks/useSmallAds';
import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const MapBoard = () => {

  const { smallAds, setSmallAds  } = useSmallAds();

  const [loading, setLoading] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: 19.11126818407638,
    latitude: 51.963213096831396,
    zoom: 5.4107,
  });
  const [currentPinId, setCurrentPinId] = useState<string | null>(null);
  const [showCreatorPin, setShowCreatorPin] = useState(false);

  const { search } = useSearch();
  const mapRef = useRef<MapRef>(null!);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`annoucements?q=${ search }`);
        setSmallAds(data.ads);
      } finally {
        setLoading(false);
      }
    })();
  }, [search]);


  return (
    <>
      <Map
        { ...viewState }
        ref={ mapRef }
        style={ { width: '100%', flexGrow: 1, height: 'auto', } }
        onMove={ evt => setViewState(evt.viewState) }
        mapboxAccessToken={ process.env.REACT_APP_MAPBOX_KEY }
        mapStyle="mapbox://styles/mapbox/streets-v9"
        doubleClickZoom={ false }
      >
        <NavigationControl />
        { smallAds.map(ad => (
          <Pin key={ad.id}
               pin={ ad }
               zoom={ viewState.zoom }
               currentPinId={ currentPinId }
               flyTo={ (lat: number, lng: number) => mapRef.current?.flyTo({center: [lng, lat], duration: 1500, zoom: viewState.zoom < 9 ? 9 : viewState.zoom, offset: [0, -80] }) }
               setCurrentPinId={ setCurrentPinId } />
        )) }
        <CreateNewAdBtn isShowing={ showCreatorPin } setShowCreatorPin={ setShowCreatorPin } />
        { showCreatorPin && (
          <NewPin zoom={ viewState.zoom } lat={ viewState.latitude } lng={ viewState.longitude } setShowCreatorPin={ setShowCreatorPin } />
        ) }
      </Map>
    </>
  );
};