import { Outlet } from 'react-router-dom';
import { Header } from '../components/layout/Header/Header';
import { MapBoard } from '../components/Map/Map';
import React from 'react';

export const MainLayout = () => {

  return (
    <>
      <Header />
      <MapBoard />
      <Outlet />
    </>
  );
};