import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NewPinInfo.css';
import { useCheckForFreePlace } from '../../../hooks/useCheckForFreePlace';

interface Props {
  setShow:  Dispatch<SetStateAction<boolean>>;
  lat: number;
  lng: number;
}

export const NewPinInfo = ({ setShow, lat, lng }: Props) => {

  const checkForFreePlace = useCheckForFreePlace();
  const isFree = checkForFreePlace(lat, lng);

  return (
    <div className="NewPinInfo">
      <p>
        Drag the pin exactly to the place you want.
      </p>

      <p>
        When you are happy click add.
      </p>
     <div className="NewPinInfo_btns-box">

       { isFree ? (
         <button className="NewPinInfo__btn" onClick={ () => setShow(false) }><Link to={`/create-ad/${ lat }/${ lng }`}>Add</Link></button>
       ) : (
         <button disabled className="NewPinInfo__btn NewPinInfo__btn--disabled">Place occupied</button>
       )}
       <button className="NewPinInfo__btn" onClick={ () => setShow(false) }>Cancel</button>
     </div>
    </div>
  );
};