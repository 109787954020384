interface Props {
  username?: string;
  email: string;
  password: string;
  passwordRepetition?: string;
}

export const validateForm = (form: Props) => {
  for (const prop in form) {
    if (prop === 'username') {
      const username = form.username?.trim();
      if (!username) {
        throw new Error('Username is required.');
      } else if (username.length < 4) {
        throw new Error('Username should be at least 4 characters.');
      } else if (username.length > 25) {
        throw new Error('Username cannot be longer than 25 characters.');
      }
    } else if (prop === 'email') {
      const email = form.email?.trim();
      if (!email) {
        throw new Error('Email is required');
      } else if (email.length > 254) {
        throw new Error('Email cannot be longer than 254 characters.');
      }
    } else if (prop === 'password') {
      const password = form.password;
      if (!password) {
        throw new Error('Password is required.');
      } else if (password.length < 6) {
        throw new Error('Password should be at least 6 characters.');
      } else if (password.length > 100) {
        throw new Error('Password cannot be longer than 100 characters.');
      }
    } else if (prop === 'passwordRepetition') {
      const pwdRepetition = form.passwordRepetition;
      if (form.password && !pwdRepetition) {
        throw new Error('Repeat your password.');
      } else if (form.password !== pwdRepetition) {
        throw new Error('Passwords don\'t match.');
      }
    }
  }
};