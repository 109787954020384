import { FormEvent, useEffect, useState } from 'react';
import { AdEntityWithUsername } from 'types';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate';
import { validateNewAd } from '../../utils/validateNewAd';
import { useSmallAds } from '../../hooks/useSmallAds';
import { AdForm } from '../common/AdForm/AdForm';

export const AdEditor = () => {

  const { adId } = useParams();
  const [ad, setAd] = useState<AdEntityWithUsername | null>(null);
  const [form, setForm] = useState({ title: '', description: '', price: 0, url: '' });
  const [error, setError] = useState('');
  const { auth } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { setSmallAds } = useSmallAds();

  useEffect(() => {
    (async () => {
      try {
        const { data: { ad } } = await axiosPrivate.get(`ads/${ adId }`) as { data: { ad: AdEntityWithUsername } };
        setAd(ad);
        setForm({ title: ad.title, description: ad.description, price: ad.price, url: ad.url });
      } catch (e: any) {
        const message = e.response?.data?.message ?? e.message;
        setError(message);
      }
    })();
  }, [adId, axiosPrivate]);

  useEffect(() => {
    if (ad && ad.userId !== auth?.id) {
      navigate('/');
    }
  }, [ad, auth, navigate]);

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      validateNewAd(form);
      const { data } = await axiosPrivate.patch(`/annoucements/${ adId }`, {
        ...form,
        userId: auth?.id,
        lat: ad?.lat,
        lng: ad?.lng,
      });
      if (data.success) {
        // setSuccess(true);
        setForm({ title: '', description: '', price: 0, url: '' });
        setSmallAds(prev => {
          const smallAdsCopy = [...prev];
          return smallAdsCopy.map(smallAd => {
            if (smallAd.id === ad?.id) {
              return data.ad;
            } else
              return smallAd;
          });
        });
        navigate('/');
      }
    } catch (e: any) {
      const message = e.response?.data?.message ?? e.message;
      setError(message);
    }
  };

  return (
    <AdForm type="edit"
            handleSubmit={ handleSubmit }
            form={ form }
            setForm={ setForm }
            error={ error }
    />
  );
};