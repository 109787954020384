import { Marker } from './Marker/Marker';
import { Popup } from './Popup/Popup';
import { SmallAdEntity } from 'types';
import React from 'react';


interface Props {
  pin: SmallAdEntity;
  zoom: number;
  currentPinId: string | null;
  setCurrentPinId: React.Dispatch<React.SetStateAction<string | null>>;
  flyTo: (lat: number, lng: number) => void;
}

export const Pin = ({ pin, zoom, currentPinId, setCurrentPinId, flyTo }: Props) => {

  return (
    <div className="Pin">
      <Marker pin={ pin }
              zoom={ zoom }
              flyTo={ flyTo }
              setCurrentPinId={ setCurrentPinId } />
      { currentPinId && currentPinId === pin.id && (
        <Popup pin={ pin }
               zoom={ zoom }
               closePopup={ () => setCurrentPinId(null) } />
      ) }
    </div>
  );
};