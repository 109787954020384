import { useAuth } from './useAuth';
import { axios } from '../api/axios';
import { useLogout } from './useLogout';

export const useRefresh = () => {
  const { setAuth } = useAuth();
  const logout = useLogout();
  return async function () {
    try {
      const { data } = await axios.get('users/refresh', {
        withCredentials: true,
      });
      setAuth(prevState => {
        if (prevState === null) return null;
        return { ...prevState, accessToken: data };
      });
      return data as string;
    } catch (err) {
      await logout();
    }
  };
};