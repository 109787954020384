import { useAuth } from '../../../../../hooks/useAuth';
import './AuthorOptions.css';
import { useState } from 'react';
import { useSmallAds } from '../../../../../hooks/useSmallAds';
import { axiosPrivate } from '../../../../../api/axios';
import { useAxiosPrivate } from '../../../../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

interface Props {
  authorId: string;
  adId: string;
}

export const AuthorOptions = ({ authorId, adId }: Props) => {

  const [deleteActive, setDeleteActive] = useState(false);
  const [error, setError] = useState(false);
  const { setSmallAds } = useSmallAds();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const handleDelete = async () => {
    setError(false);
    if (!deleteActive) {
      setDeleteActive(true);
      return;
    }
    try {
      await axiosPrivate.delete(`ads/${ adId }`);
      setSmallAds(prev => {
        const prevSmallAdsCopy = [...prev];
        return prevSmallAdsCopy.filter(ad => ad.id !== adId);
      });
    } catch (er) {
      setError(true);
      setDeleteActive(false);
    }
  }


  if (auth?.id !== authorId) {
    return null;
  }
  return (
    <>
    <div className="AuthorOptions">
      <button className="AuthorOptions__btn AuthorOptions__btn-edit"><Link to={`/edit-ad/${ adId }`} >Edit</Link></button>
      <button onClick={ handleDelete } className={`AuthorOptions__btn AuthorOptions__btn-delete ${ deleteActive ? 'AuthorOptions__btn-delete--active' : '' }`}>{ deleteActive ? 'Sure?' : 'Delete' }</button>
    </div>
      { error && <p style={{ color: 'red' }}>Something went wrong.</p>}
  </>
  );
};