interface Props {
  title: string;
  description: string;
  price: number;
  url: string;
}

export const validateNewAd = ({ title, description, url, price }: Props) => {
  if (!title) {
    throw new Error('Title is required.');
  }
  if (!url) {
    throw new Error('Url is required.');
  }
};