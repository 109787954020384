import { HiOutlineSearch } from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
import './Searchbar.css';
import { FormEvent, useState } from 'react';
import { useSearch } from '../../../../hooks/useSearch';


export const Searchbar = () => {

  const [active, setActive] = useState(false);
  const [term, setTerm] = useState('');
  const { setSearch } = useSearch();


  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    setSearch(term);
  };

  const handleEndSearch = () => {
    setTerm('');
    setSearch('');
  };

  return (
    <div className="Searchbar__wrapper">
      <div className="Searchbar"
           style={ { boxShadow: active ? '0 0 10px white' : '' } }>
        <form className="Searchbar__form"
              onSubmit={ handleSearch }>
          <input type="text"
                 value={ term }
                 onChange={ e => setTerm(e.target.value) }
                 className="Searchbar__input"
                 onFocus={ () => setActive(true) }
                 onBlur={ () => setActive(false) }
                 placeholder="search by title..." />
          { !!term && <IoClose className="Searchbar__close-icon"
                               onClick={ handleEndSearch } /> }
          <button className="Searchbar__search-btn"
                  type="submit">
            <HiOutlineSearch className="Searchbar__loop-icon" />
          </button>
        </form>
      </div>
    </div>
  );
};