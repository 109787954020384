import './Header.css';
import { Searchbar } from './Searchbar/Searchbar';
import { AuthBtns } from './AuthBtns/AuthBtns';
import { Link } from 'react-router-dom';

export const Header = () => {

  return (
    <header className="Header">
      <h1 className="Header__title"><Link to="/">Mapbox Ads</Link></h1>
      <Searchbar />
      <AuthBtns />
    </header>
  );
};