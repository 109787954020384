import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import './AdCreator.css';
import { FormEvent, useEffect, useState } from 'react';
import { validateNewAd } from '../../utils/validateNewAd';
import { useSmallAds } from '../../hooks/useSmallAds';
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate';
import { AdForm } from '../common/AdForm/AdForm';

export const AdCreator = () => {

  const [form, setForm] = useState({ title: '', description: '', price: 0, url: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { auth } = useAuth();
  const { lat, lng } = useParams();
  const { setSmallAds } = useSmallAds();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();


  useEffect(() => {
    setError('');
  }, [form]);

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      validateNewAd(form);
      const { data } = await axiosPrivate.post('/annoucements', {...form, userId: auth?.id, lat, lng  });
      if (data.success) {
        setSuccess('Success! You can now login by your email and password.');
        setForm({ title: '', description: '', price: 0, url: '' });
        setSmallAds(prev => [...prev, data.ad]);
        navigate('/');
      }
    } catch (e: any) {
      const message = e.response?.data?.message ?? e.message;
      setError(message);
    }
  };

  return (
    <AdForm handleSubmit={ handleSubmit } form={ form } setForm={ setForm } error={ error } type="add" success={ success } />
  );
};



// return (
//   <div className="AdCreator">
//     <div className="AdCreator__body"
//          onBlur={ handleBlur }
//          tabIndex={ 1 }>
//       <Link to="/"><IoClose className="Auth__close-icon" /></Link>
//       <form onSubmit={ handleSubmit }>
//         <h2>Add new</h2>
//         <InputField label="Title">
//           <input ref={ titleRef }
//                  type="text"
//                  required
//                  onChange={ e => handleChange('title', e.target.value) }
//                  value={ form.title } />
//         </InputField>
//
//         <InputField label="Desrciption">
//             <textarea placeholder="you can leave it empty"
//                       onChange={ e => handleChange('description', e.target.value) }
//                       value={ form.description } />
//         </InputField>
//
//
//         <InputField label="Price">
//           <input type="number"
//                  max={ 99999999 }
//                  min={ 0 }
//                  onChange={ e => handleChange('price', Number(e.target.value)) }
//                  value={ form.price.toString() } />
//         </InputField>
//
//         <InputField label="Link url">
//           <input type="url"
//                  required
//                  onChange={ e => handleChange('url', e.target.value) }
//                  value={ form.url } />
//         </InputField>
//
//         { !!error && <p className="error">{ error }</p> }
//         { success && <div><p className="success">Success! You can now login by your email and password.</p>
//           <p className="success">Click <Link to="/login">here</Link> to login.</p></div> }
//
//         <button type="submit">
//           Add
//         </button>
//       </form>
//     </div>
//   </div>
// );