import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

export const SearchContext = createContext<{ search: string, setSearch: Dispatch<SetStateAction<string>> }>({ search: '', setSearch: () => {}});

interface Props {
  children?: React.ReactNode;
}

export const SearchProvider = ({ children }: Props) => {

  const [search, setSearch] = useState<string>('');

  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      { children }
    </SearchContext.Provider>
  );
};
