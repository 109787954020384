import { useAuth } from '../../hooks/useAuth';
import './CreateNewAdBtn.css';
import { MdOutlineAddLocationAlt } from 'react-icons/md';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  setShowCreatorPin: Dispatch<SetStateAction<boolean>>;
  isShowing: boolean;
}

export const CreateNewAdBtn = ({ setShowCreatorPin, isShowing }: Props) => {

  const { auth } = useAuth();

  if (!auth) return null;

  return (
    <button disabled={ isShowing } onClick={ () => setShowCreatorPin(true) } className="CreateNewAdBtn noselect">New <MdOutlineAddLocationAlt className="CreateNewAdBtn__icon" /></button>
  );
};