import React, { MutableRefObject, useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import './InputField.css';

interface Props {
  children: React.ReactNode;
  label: string;
  pwdRef?: MutableRefObject<HTMLInputElement>;
}


export const InputField = ({ children, label, pwdRef }: Props) => {

  const show = () => {
    pwdRef?.current?.setAttribute('type', 'text');
  };

  const hide = () => {
    pwdRef?.current?.setAttribute('type', 'password');
  };


  const toggle = () => {

    pwdRef?.current?.getAttribute('type') === 'password' ? show() : hide();
  };

  return (
    <div className="InputField">
      <label className="InputField__label noselect">
        <span className="InputField__label-span">{ label }</span>
        <span></span>
        { children }
      </label>
      { pwdRef && <button type="button"
                          className="button"
                          onClick={ toggle }
      >
        <AiFillEye />
      </button> }
    </div>
  );
};