import React, { createContext, useState } from 'react';
import { SmallAdEntity } from 'types';

export const SmallAdsContext = createContext<{ smallAds: SmallAdEntity[], setSmallAds:  React.Dispatch<React.SetStateAction<SmallAdEntity[]>> }>({ smallAds: [], setSmallAds: () => {} });

interface Props {
  children?: React.ReactNode;
}

export const SmallAdsProvider = ({ children }: Props) => {
  const [smallAds, setSmallAds] = useState<SmallAdEntity[]>([]);

  return (
    <SmallAdsContext.Provider value={{ smallAds, setSmallAds }}>
      { children }
    </SmallAdsContext.Provider>
  )
}