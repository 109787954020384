import { useRefresh } from './useRefresh';
import { axiosPrivate } from '../api/axios';
import { useEffect } from 'react';
import { useAuth } from './useAuth';
import { useLogout } from './useLogout';

export const useAxiosPrivate = () => {
  const refresh = useRefresh();
  const { auth } = useAuth();
  const logout = useLogout();

  useEffect(() => {
    const reqIntercept = axiosPrivate.interceptors.request.use(
      (config: any) => {
      if (!config.headers['authorization']) {
        config.headers['authorization'] = `Bearer ${ auth?.accessToken }`;
      }
      return config;
    },
      (error) => Promise.reject(error));

    const resIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevReq = error?.config;
        if (error.response.status === 403 && !prevReq.sent) {
          prevReq.sent = true;
          prevReq.headers['authorization'] = `Bearer ${ await refresh() }`
          return axiosPrivate(prevReq);
        }
        return Promise.reject(error);
      });

    return () => {
      axiosPrivate.interceptors.request.eject(reqIntercept);
      axiosPrivate.interceptors.response.eject(resIntercept);
    }
  }, [auth, refresh]);

  return axiosPrivate;
}